














import { Component, Vue } from 'vue-property-decorator'

import RegisterServiceUserForm from '@/partials/forms/Services/RegisterServiceUserForm.vue'

@Component({
  components: {
    RegisterServiceUserForm
  }
})
export default class SelfRegisterCreate extends Vue {
  person: ServicePerson | EventPerson = 'chief'

  created () {
    this.person = this.$route.meta.person
  }
}
